import styles from './PriceList.module.css';
import SubCard from '../components/SubCard';
import { useEffect, useState } from 'react';
import fetchSubscriptions from '../api/fetchSubscriptions';
import Loading from '../components/Loading';

function PriceList() {
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        const loadSubscriptions = async () => {
            try {
                const data = await fetchSubscriptions();
                setSubscriptions(data);
                setIsLoading(false);
            } catch (err) {
                console.error(err);
            }
        };
        loadSubscriptions();
    }, []);

    return (
        <>
            {isLoading && <Loading />}
            <div className={styles.content}>
                <h1>Прайс-Лист</h1>
                <p>Представляем вашему вниманию уникальные подписки, которые откроют двери в мир сообщества "Ренессанс" — пространство, где собираются умные, талантливые и успешные люди, стремящиеся к великим свершениям. Каждая из наших подписок предоставит вам доступ к эксклюзивным мероприятиям, ценным знаниям и мощной сети контактов.</p>
                <div className={styles.cards}>
                    {subscriptions.map((sub, index) => {
                        return(
                            <SubCard key={index} sub={sub} />
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export default PriceList;