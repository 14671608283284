import React, {createContext, useState, useEffect, useContext} from 'react';
import fetchMembers from '../api/fetchMembers';

export const MembersContext = createContext();

export const MembersProvider = ({children}) => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);

    const findMember = (memberId) => members.find(member => member.id === memberId)

    useEffect(() => {
        const loadMembers = async () => {
            try {
                const data = await fetchMembers();
                setMembers(data);
            } catch(err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        loadMembers();
    }, []);

    return (
        <MembersContext.Provider value={{members, loading, findMember}}>
            {children}
        </MembersContext.Provider>
    )
}

export const useMembers = () => useContext(MembersContext);