import axios from "axios";

const API_URL = 'https://rnsns.ru.net/api/member_subscriptions';

const fetchMemberSubscriptions = async (memberId) => {
    const response = await axios.get(`${API_URL}/${memberId}`);
    const data = response.data;

    const renamedData = data.map(sub => ({
        id: sub.id,
        memberId: sub.member_id,
        name: sub.name,
        options: sub.features,
        price: sub.price,
    }));

    return renamedData;
};

export default fetchMemberSubscriptions;