import MemberCard from "../components/MemberCard";
import styles from './Members.module.css';
import { useMembers } from "../contexts/MembersContext";
import Loading from "../components/Loading";

function Members() {
    const {members, loading} = useMembers();

    return(
        <>
            {loading && <Loading />}
            <div className={styles.content}>
                <h1>Представители Ренессанса</h1>
                <div className={styles.cards}>
                    {members.map(member => {
                        return(
                            <MemberCard key={member.id} id={member.id} member={member} />
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default Members;