import axios from 'axios';

//const API_URL = 'http://localhost:5000/api/members';
const API_URL = 'https://rnsns.ru.net/api/members';

const fetchMembers = async () => {
    const response = await axios.get(API_URL);
    const data = response.data;

    const renamedData = data.map(member => ({
        id: member.id,
        fullName: member.fullname,
        goldenQuote: member.golden_quote,
        desc: member.description,
        img: member.image_url,
        tg: member.telegram,
    }));

    return renamedData;
}

export default fetchMembers;