import { BrowserRouter, Routes, Route } from "react-router-dom";

import SharedHeader from "./pages/SharedHeader";
import Home from "./pages/Home";
import SharedMembersLayout from './pages/SharedMembersLayout';
import Members from "./pages/Members";
import Member from "./pages/Member";
import PriceList from "./pages/PriceList";
import ShoppingCart from "./pages/ShoppingCart";
import {CartProvider} from './contexts/ShoppingCartContext';
import { MembersProvider } from "./contexts/MembersContext";
import DD from "./pages/DD";

function App() {
  return (
    <BrowserRouter>
    <MembersProvider>
    <CartProvider>
      <Routes>
        <Route path="/" element={<SharedHeader />}>
          <Route index element={<Home />} />
          <Route path="members" element={<SharedMembersLayout />}>
            <Route index element={<Members />} />
            <Route path=":membersId" element={<Member />} />
          </Route>
          <Route path="price_list" element={<PriceList />} />
          <Route path="shopping_cart" element={<ShoppingCart />} />
          <Route path="dd" element={<DD />} />
        </Route>
      </Routes>
    </CartProvider>
    </MembersProvider>
    </BrowserRouter>
  );
}

export default App;
