import React, {createContext, useContext, useState} from "react";

const CartContext = createContext({ cart: [], setCart: () => {}});

export const CartProvider = ({children}) => {
    const [cart, setCart] = useState([]);

    const addToCart = (item) => {
        setCart(prevCart => [...prevCart, {...item, count: 1}]);
    };

    const removeFromCartId = (itemId) => {
        setCart((prevCart) => prevCart.filter((item) => item.id !== itemId))
    }

    const removeFromCart = (itemMemberId) => {
        setCart((prevCart) => prevCart.filter((item) => item.memberId !== itemMemberId))
    };

    const isInCart = (itemId) => cart.some((item) => item.id === itemId);

    const isTypeInCart = (typeId) => cart.some((item) => item.memberId === typeId);

    const updateCount = (itemId, count) => {
        setCart(prevCart => prevCart.map(item => item.id === itemId ? {...item, count} : item));
    }

    const calcTotal = () => {
        return cart.reduce((total, item) => total + item.price * (item.count || 1), 0);
    }

    const clearCart = () => {
        setCart([]);
    }

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCartId, removeFromCart, isInCart, isTypeInCart, updateCount, calcTotal, clearCart }}>
            {children}
        </CartContext.Provider>
    );
}

export const useCart = () => useContext(CartContext);