import axios from "axios";

const API_URL = 'https://rnsns.ru.net/api/price_list';

const fetchSubscriptions = async () => {
    const response = await axios.get(API_URL);
    const data = response.data;

    const renamedData = data.map(sub => ({
        id: sub.id,
        memberId: null,
        name: sub.name,
        options: sub.options,
        price: sub.price,
    }));

    return renamedData;
}

export default fetchSubscriptions;