import { IconContext } from 'react-icons';
import { FaCheck } from "react-icons/fa";
import { useCart } from '../contexts/ShoppingCartContext';
import styles from './SubCard.module.css';

function SubCard({sub}) {
    const {id, memberId, name, options, price} = sub;
    const {addToCart, removeFromCart, isInCart, isTypeInCart} = useCart();
    const inCart = isInCart(id);
    const typeInCart = isTypeInCart(memberId)

    const clickHandler = () => {
        if (inCart)
            removeFromCart(memberId);
        else if (typeInCart) {
            removeFromCart(memberId);
            addToCart(sub);
        } else
            addToCart(sub);
    }

    return(
        <article className={styles.card}>
            <h1 className={styles.underline}>{name}</h1>
            <ul>
                {options.map((option, index) => {
                    return(
                        <li key={index}>
                            <i><IconContext.Provider value={{className: `${styles.checkIcon}`}} >
                                <FaCheck />
                            </IconContext.Provider></i> {option}
                        </li>
                    );
                })}
            </ul>
            <div className={styles.buyBtnWrapper}>
                <h3>{price}₽/нед</h3>
                <button className={`${styles.buyBtn} ${inCart ? styles.clicked : styles.unClicked}`} onClick={clickHandler}>{inCart ? 'В корзине' : 'Купить!'}</button>
            </div>
        </article>
    );
}

export default SubCard;