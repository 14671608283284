import axios from 'axios';
import { useEffect, useState } from 'react';
import styles from './DD.module.css';
import Loading from '../components/Loading';

function DD() {
    const [didi, setDidi] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchDidi = async () => {
            try {
                const response = await axios.get('https://rnsns.ru.net/api/dd');
                setDidi(response.data);
            } catch (err) {
                console.error("Error", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDidi();
    }, [])



    return (
        <>
            <div className={styles.content}>
                {isLoading ? (<Loading />) : (
                    <section className={styles.card}>
                        <img src={`https://rnsns.ru.net/${didi.img}`} alt={`Photo ${didi.fullName}`} />
                        <div className={styles.cardInfo}>
                            <h1 className={styles.underline}>{didi.fullName}</h1>
                            <div className={styles.cardInfoMore}>
                                <h3>"{didi.goldenQuote}"</h3>
                                <p>{didi.desc1}</p>
                                <p>{didi.desc2}</p>
                                <p>{didi.desc3}</p>
                                <p>{didi.desc4}</p>
                                <p>{didi.desc5}</p>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        </>
    );
}

export default DD;