import { useParams } from "react-router-dom";
import SubCard from "../components/SubCard";
import styles from './Member.module.css';
import { useEffect, useState } from "react";
import fetchMemberSubscriptions from "../api/fetchMemberSubscriptions";
import Loading from "../components/Loading";
import { useMembers } from "../contexts/MembersContext";

function Member() {
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const {membersId} = useParams();
    const {members, loading} = useMembers();

    const member = members.find(member => member.id === Number(membersId));

    useEffect(() => {
        const loadSubscriptions = async () => {
            try {
                const data = await fetchMemberSubscriptions(Number(membersId));
                setSubscriptions(data);
                setIsLoading(false);
            } catch (err) {
                console.err(err);
            }
        };
        loadSubscriptions();
    }, [membersId, member]);

    if(!member) 
        return <p>Мембер не найден</p>

    const {fullName, goldenQuote, desc, img} = member;

    return (
        <>
            {(loading || isLoading) && <Loading />}
            <div className={styles.content}>
                <section className={styles.card}>
                    <img src={img} alt={`Photo ${fullName}`} />
                    <div className={styles.cardInfo}>
                        <h1 className={styles.underline}>{fullName}</h1>
                        <div className={styles.cardInfoMore}>
                            <h3>"{goldenQuote}"</h3>
                            <p>{desc}</p>
                        </div>
                    </div>
                </section>
                <section className={styles.subs}>
                    {subscriptions.map((sub, index) => {
                        return(
                            <SubCard key={index} sub={sub}/>
                        );
                    })}
                </section>
            </div>
        </>
    );
}

export default Member;