import styles from './ShoppingCart.module.css';

import { useCart } from '../contexts/ShoppingCartContext';
import { useMembers } from '../contexts/MembersContext';
import SubCardCart from '../components/SubCardCart';

function ShoppingCart() {
    const { cart, calcTotal, clearCart } = useCart();
    const { findMember } = useMembers()

    return(
        <div className={styles.content}>
            <h1>Корзина</h1>
            {cart && cart.length <= 0 ? (
                <h2>Корзина пуста!</h2>
            ) : (
                <>
                <div className={styles.cartContainer}>
                    <ul className={styles.ulWrapper}>
                        {cart.map((sub, index) => {
                            return(
                                <SubCardCart key={index} id={sub.id} name={sub.name} memberName={findMember(sub.memberId)?.fullName} price={sub.price}/>
                            );
                        })}
                    </ul>
                    <div className={styles.inCartWrapper}>
                        <h3>В корзине</h3>
                        <h3>{calcTotal()}₽</h3>
                        <button className={styles.buyBtn}>Перейти к оформлению</button>
                    </div>
                </div>
                <div className={styles.clearBtnContainer}>
                    <button className={styles.clearBtn} onClick={clearCart}>Очистить корзину</button>
                </div>
                </> 
            )}
        </div>
    );
}

export default ShoppingCart;