import styles from './SubCardCart.module.css';
import { IconContext } from 'react-icons';
import {IoIosCloseCircleOutline} from 'react-icons/io';
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import { useCart } from '../contexts/ShoppingCartContext';
import { useEffect, useState } from 'react';

function SubCardCart({id, name, memberName, price}) {
    const {removeFromCartId, updateCount} = useCart();
    const [count, setCount] = useState(1);

    if (!memberName)
        memberName = 'Общие';

    function closeClickHandler() {
        removeFromCartId(id);
    }

    useEffect(() => {
        updateCount(id, count);
    }, [count, id])

    return (
        <li className={styles.container}>{memberName} {name}
            <div className={styles.countContainer}>
                <button className={`${styles.wrapperBtn}`} onClick={() => setCount(count - 1)} disabled={count <= 1}>
                    <IconContext.Provider value={{className: `${styles.wrapperIcon}`}}><CiCircleMinus /></IconContext.Provider>
                </button>
                <input type='number' value={count} onChange={e => setCount(e.target.value)}/>
                <button className={`${styles.wrapperBtn}`} onClick={() => setCount(count + 1)} disabled={count >= 4}>
                    <IconContext.Provider value={{className: `${styles.wrapperIcon}`}}><CiCirclePlus /></IconContext.Provider>
                </button>
            </div> 
            <div className={styles.price}>{price * count}₽</div>
            <button className={styles.wrapperBtn} onClick={closeClickHandler}>
                <IconContext.Provider value={{className: `${styles.wrapperIcon}`}} >
                    <IoIosCloseCircleOutline />
                </IconContext.Provider>
            </button>
        </li>
    );
}

export default SubCardCart;