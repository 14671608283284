import React, { useState } from 'react';
import styles from './NavBar.module.css';
import { NavLink } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import Modal from './ModalWindow';

function NavBar() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            <nav className={styles.navbar}>
                <NavLink to='/' className={styles.logo}>
                    <img src='../images/rnsnsLogo.png' alt="Logo" />
                </NavLink>
                <NavLink to='/' className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : styles.link}>Главная</NavLink>
                <NavLink to='/members' className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : styles.link}>Представители ренессанса</NavLink>
                <NavLink to='/price_list' className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link}` : styles.link}>Прайс-лист</NavLink>
                <button onClick={openModal} className={styles.button}>Ренессанс Картинки</button>
                <NavLink to='/shopping_cart' className={({ isActive }) => isActive ? `${styles.activeLink} ${styles.link} ${styles.shoppingCart}` : `${styles.link} ${styles.shoppingCart}`}>
                    <FaShoppingCart className={styles.shoppingCartLogo} />
                </NavLink>
            </nav>
            <Modal isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
}

export default NavBar;

